@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-background: #1a202c;
  --color-text: #f7fafc;
  --color-primary: #4fd1c5;
  --color-secondary: #2d3748;
}

body {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(to bottom right, var(--color-background), #2d3748);
  color: var(--color-text);
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

h1, h2, h3 {
  font-weight: 700;
}

a {
  color: var(--color-primary);
  transition: color 0.3s ease;
}

a:hover {
  color: #38b2ac;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-background);
}

.btn-primary:hover {
  background-color: #38b2ac;
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: var(--color-text);
}

.btn-secondary:hover {
  background-color: #4a5568;
}

.card {
  background-color: rgba(45, 55, 72, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.skill-tag {
  background-color: var(--color-secondary);
  color: var(--color-text);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
}

.skill-tag:hover {
  background-color: #4a5568;
}

/* Glowing effect for headings */
h1, h2 {
  text-shadow: 0 0 10px rgba(79, 209, 197, 0.5);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-background);
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #38b2ac;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 0 0.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

/* Animation for cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  animation: fadeInUp 0.5s ease-out;
}